import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import { A11y, Autoplay } from 'swiper'

import Col from '@Components/atoms/Col'
import Container from '@Components/atoms/Container'
import Row from '@Components/atoms/Row'
import { FontSizeText, FontWeightText } from '@Components/atoms/Text/Text'
import { PropsWithClassName } from '@Components/helper'

import { QossmicEvent } from '../CommunityEvents/CommunityEvents'
import {
  Content,
  Root,
  StyledHeadline,
  StyledSwiper,
  StyledSwiperSlide,
  StyledText,
  Tags,
} from './EventSwiper.styles'

interface Props extends PropsWithClassName {
  events: QossmicEvent[]
}

const EventSwiper = (props: Props): React.ReactElement => {
  const { events } = props
  const router = useRouter()

  const reverse_events = [...events].reverse()

  const renderTags = (tags: string[]): JSX.Element[] => {
    return tags.map((tag, index) => {
      if (index < tags.length - 1) {
        return (
          <React.Fragment key={index}>
            {tag}
            <span>|</span>
          </React.Fragment>
        )
      } else {
        return <React.Fragment key={index}>{tag}</React.Fragment>
      }
    })
  }

  return (
    <Root
      className={props.className + ' eventswiper'}
      data-testid={'EventSwiper-root'}
    >
      <Container fluid>
        <Row>
          <Col xs={12}>
            <StyledSwiper
              modules={[A11y, Autoplay]}
              loop={true}
              speed={1500}
              initialSlide={1}
              autoplay={{ delay: 5000 }}
              centeredSlides={true}
              slidesPerView={'auto'}
              breakpoints={{
                1: {
                  slidesPerView: 1,
                  spaceBetween: 32,
                },
                1024: {
                  slidesPerView: 2,
                  spaceBetween: 32,
                },
                1200: {
                  slidesPerView: 3,
                  spaceBetween: 32,
                },
                1440: {
                  slidesPerView: 4,
                  spaceBetween: 32,
                },
              }}
            >
              {reverse_events &&
                reverse_events.map((event, index) => {
                  // if (!event.thumbnailEvent) {
                  //   return
                  // }
                  return (
                    <StyledSwiperSlide
                      onClick={() => router.push(event.url)}
                      key={index + event.location + event.title}
                    >
                      <Content>
                        <Link href={event.url} passHref>
                          <StyledHeadline styleAs={4} importance={4}>
                            {event.name}
                          </StyledHeadline>
                          <StyledText
                            size={FontSizeText.medium}
                            weight={FontWeightText.normal}
                          >
                            {event.teaser}
                          </StyledText>
                          {event.tags && (
                            <Tags
                              size={FontSizeText.medium}
                              weight={FontWeightText.normal}
                            >
                              {renderTags(event.tags)}
                            </Tags>
                          )}
                        </Link>
                      </Content>
                    </StyledSwiperSlide>
                  )
                })}
            </StyledSwiper>
          </Col>
        </Row>
      </Container>
    </Root>
  )
}

export { EventSwiper }
