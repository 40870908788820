import React from 'react'

import { PropsWithClassName } from '@Components/helper'

import { Root } from './ScrollDown.styles'

interface Props extends PropsWithClassName {}

const ScrollDown = (props: Props): React.ReactElement => {
  const handleClick = (): void => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: 'smooth',
    })
  }

  return (
    <Root
      className={props.className}
      data-testid={'ScrollDown-root'}
      width="78"
      height="78"
      viewBox="0 0 78 78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => handleClick()}
    >
      <circle cx="39" cy="39" r="39" fill="#C2067B" />
      <path
        d="M53.2549 42.42L38.6995 56.9754L24.1442 42.42"
        stroke="#060E2B"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.2549 24.1428L38.6995 38.6982L24.1442 24.1428"
        stroke="#060E2B"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Root>
  )
}

export { ScrollDown }
