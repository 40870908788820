import Link from 'next/link'
import styled from 'styled-components'

import Col from '@Components/atoms/Col'
import Headline from '@Components/atoms/Headline'
import Logo from '@Components/atoms/Logos/Logo'
import Row from '@Components/atoms/Row'
import ScrollDown from '@Components/atoms/ScrollDown'
import Text from '@Components/atoms/Text'

export const Root = styled.section`
  text-align: center;
  position: relative;
  color: ${(props) => props.theme.palette.default.white};
  height: 100vh;
  padding-top: 81px;
  background: linear-gradient(-45deg, #243059, #060e2b, #80004f);
  background-size: 400% 400%;
  background-position: 50% 50%;
  overflow: hidden;
`

export const StyledRow = styled(Row)`
  height: 100%;
`

export const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  align-items: center;
`

export const LogoWrap = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  &:hover {
    animation: none;
  }
`

export const StyledLogo = styled(Logo)`
  position: relative;
  z-index: 10;
  margin-bottom: 30px;
  height: 100px;
  width: auto;
  path {
    transition: fill 200ms;
  }
  @media (min-width: ${(props) => props.theme.device.tablet}) {
    width: auto;
    height: auto;
  }
`

export const StyledText = styled(Text)`
  position: relative;
  z-index: 10;
  text-transform: uppercase;
  user-select: none;
  margin-bottom: 0;
  color: ${(props) => props.theme.palette.default.white};
`

export const StyledHeadline = styled(Headline)`
  position: relative;
  z-index: 10;
  user-select: none;
  color: ${(props) => props.theme.palette.default.white};
  margin-bottom: ${(props) => props.theme.margin.default};
  hyphens: none;
`

export const StyledScrollDown = styled(ScrollDown)`
  position: relative;
  z-index: 10;
`

export const StyledLink = styled(Link)`
  text-decoration: underline;
  color: ${(props) => props.theme.palette.default.white};
  position: relative;
  transition: color 200ms;
  hyphens: none;
  &:hover {
    color: ${(props) => props.theme.palette.default.primary};
  }
`

export const StyledLinkText = styled(Link)`
  text-decoration: none !important;
  color: ${(props) => props.theme.palette.default.white};
  position: relative;
  transition: color 200ms;
  hyphens: none;
  &:link {
    color: ${(props) => props.theme.palette.default.white}!important;
  }
  &:visited {
    color: ${(props) => props.theme.palette.default.white}!important;
  }
  &:hover {
    color: ${(props) => props.theme.palette.default.primary};
  }
`
