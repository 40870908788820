import styled from 'styled-components'

import Quote from '@Components/atoms/Quote'

export const Root = styled.div`
  display: flex;
  flex-direction: column;
`
export const StyledQuote = styled(Quote)`
  margin-top: 3rem;
  font-size: 34px;
`
