import React from 'react'

import Col from '@Components/atoms/Col'
import Container from '@Components/atoms/Container'
import Row from '@Components/atoms/Row'
import Text, { FontSizeText, FontWeightText } from '@Components/atoms/Text'
import { PropsWithClassName } from '@Components/helper'
import CustomerSwiper from '@Components/molecules/CustomerSwiper'
import { CustomerSwiperSlides } from '@Components/molecules/CustomerSwiper/CustomerSwiper'
import SectionTeaser from '@Components/molecules/SectionTeaser'
import { SectionTeaserBackground } from '@Components/molecules/SectionTeaser/SectionTeaser'
import ServicesTeaser from '@Components/molecules/ServicesTeaser'
import Teaser from '@Components/molecules/Teaser'
import Technologies from '@Components/molecules/Technologies'
import {
  TechList,
  TechnologiesBackground,
} from '@Components/molecules/Technologies/Technologies'
import TextAndSwiper from '@Components/organisms/TextAndSwiper'
import { TextAndSwiperBackground } from '@Components/organisms/TextAndSwiper/TextAndSwiper'
import { Routes } from '@Components/Routes'

import { Employee } from '@Assets/employees/swiper/data'

import { Root, StyledQuote } from './IndexPage.styles'

interface Props extends PropsWithClassName {
  employees: Employee[]
}

const IndexPage = (props: Props): React.ReactElement => {
  const { employees } = props

  const renderSlideContent = (employee: Employee) => {
    return (
      <>
        <StyledQuote>{employee.quote}</StyledQuote>
        <Text size={FontSizeText.normal} weight={FontWeightText.normal}>
          {employee.name}
        </Text>
      </>
    )
  }
  return (
    <Root className={props.className} data-testid={'IndexPage-root'}>
      <Teaser />
      <CustomerSwiper
        target="no-solution"
        headline="Lösungen"
        slides={CustomerSwiperSlides}
      />
      <SectionTeaser
        section="Services"
        headlineLight="Wir sind in jeder Projektphase der richtige Partner"
        text="Wir zeigen Ihnen, wie Sie Ihre Software aufbauen, erweitern oder modernisieren. Und wir sind erst zufrieden, wenn Sie ohne uns klarkommen."
        buttonText="Weitere Infos"
        buttonLink={Routes.services}
        background={SectionTeaserBackground.bluedeep}
      />
      <ServicesTeaser />
      <Container>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Technologies
              background={TechnologiesBackground.bluedeep}
              technologies={[
                TechList.php,
                TechList.symfony,
                TechList.sulu,
                TechList.reactjs,
                TechList.nextjs,
                TechList.git,
                TechList.typescript,
                TechList.traefik,
                TechList.docker,
                TechList.kubernetes,
                TechList.ansible,
                TechList.gitlab,
              ]}
              isHomePage={true}
            />
          </Col>
        </Row>
      </Container>
      <TextAndSwiper
        background={TextAndSwiperBackground.light}
        eyebrow="Jobs"
        headline="Arbeiten bei QOSSMIC"
        text="Wir suchen Menschen, die zu uns passen, unsere Werte und Ansprüche teilen. Gerne auch Menschen mit ganz neuen Denkweisen oder ungewöhnlichen Lebensläufen, die unser Team menschlich wie fachlich bereichern."
        buttonText="Weitere Infos"
        link={Routes.jobs}
        slides={employees.map((emp) => {
          return {
            image: emp.img.src,
            content: renderSlideContent(emp),
            alt: emp.img.alt,
          }
        })}
      />
    </Root>
  )
}

export { IndexPage }
