import type { NextPage } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { jsonLdScriptProps } from 'react-schemaorg'
import { Organization } from 'schema-dts'

import SectionTeaser from '@Components/molecules/SectionTeaser'
import { SectionTeaserBackground } from '@Components/molecules/SectionTeaser/SectionTeaser'
import { eventsDummy } from '@Components/organisms/CommunityEvents/CommunityEvents'
import EventSwiper from '@Components/organisms/EventSwiper'
import { MetaDataInfo } from '@Components/organisms/MetaData/MetaData'
import IndexPage from '@Components/pages/IndexPage'
import { Routes } from '@Components/Routes'
import DefaultTemplate from '@Components/templates/DefaultTemplate'

import { Employee, EMPLOYEES_SLIDER } from '@Assets/employees/swiper/data'

const metaData: MetaDataInfo = {
  title: 'QOSSMIC - Ihr Dienstleister für Software-Entwicklung',
  metaDescription:
    'Wir sind leidenschaftliche Software-Entwickler:innen, die für Sie und mit Ihnen Anwendungen kreieren - und das mit Ehrgeiz und Spaß.',
}

const renderSchema = () => {
  return (
    <script
      {...jsonLdScriptProps<Organization>({
        '@context': 'https://schema.org',
        '@type': 'Organization',
        parentOrganization: {
          '@type': 'Organization',
          name: 'QOSSMIC',
          brand: 'QOSSMIC',
          legalName: 'QOSSMIC GmbH',
          foundingDate: '01.08.2011',
          url: 'https://www.qossmic.com',
          logo: 'https://www.qossmic.com/share/qossmic-logo.jpg',
          address: {
            '@type': 'PostalAddress',
            streetAddress: 'Balthasarstr. 79',
            addressLocality: 'Köln',
            addressRegion: 'NRW',
            postalCode: '50670',
          },
          contactPoint: {
            '@type': 'ContactPoint',
            contactType: 'customer support',
            telephone: '[+492211653540]',
            email: 'contact@qossmic.com',
          },
        },
        subOrganization: [
          {
            '@type': 'Organization',
            name: 'QOSSMIC Application Development',
            legalName: 'QOSSMIC Application Development GmbH',
            brand: 'QOSSMIC',
            foundingDate: '01.07.2015',
            address: {
              '@type': 'PostalAddress',
              streetAddress: 'Balthasarstr. 79',
              addressLocality: 'Köln',
              addressRegion: 'NRW',
              postalCode: '50670',
            },
          },
        ],
      })}
    />
  )
}

type Props = {
  employees: Employee[]
}
const HomePage: NextPage<Props> = (props: Props) => {
  return (
    <DefaultTemplate
      topContent={<IndexPage employees={props.employees} />}
      footerContent={
        <>
          <SectionTeaser
            section="Community"
            headlineDark="Werde Mitglied in unserer Community und bleibe auf dem Laufenden."
            text="Wir widmen uns in regelmäßigen Abständen den verschiedensten Themen aus Softwareentwicklung, -architektur und Produktentwicklung."
            buttonText="Weitere Infos"
            buttonLink={Routes.community}
            background={SectionTeaserBackground.transparent}
          />
          <EventSwiper events={eventsDummy} />
        </>
      }
      transparentBar={false}
      dataTestId="index"
      metaData={metaData}
      additionalMetaTags={renderSchema()}
    />
  )
}

export const getStaticProps = async ({ locale }: { locale: string }) => ({
  props: {
    employees: EMPLOYEES_SLIDER.sort(() =>
      Math.random() > 0.5 ? 1 : -1
    ).slice(0, 3) /* shuffle and pick 3 */,
    page: metaData.title,
    ...(await serverSideTranslations(locale, ['index', 'global'])),
  },
})

export default HomePage
