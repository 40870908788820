import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'

import Headline from '@Components/atoms/Headline'
import Text from '@Components/atoms/Text'

export const Root = styled.div`
  position: relative;
  z-index: 10;
  overflow: visible;
  margin-top: ${(props) => props.theme.margin.half};
  margin-bottom: ${(props) => props.theme.margin.triple};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.palette.default.blueDeep};
  color: ${(props) => props.theme.palette.default.white};
  padding: 2.5rem 1.5rem;
  text-align: center;
  width: 100%;
  overflow: visible;

  &:hover {
    cursor: pointer !important;
    background-color: ${(props) => props.theme.palette.default.blueDark};
    transition: background ease 1s;
  }
`

export const StyledHeadline = styled(Headline)`
  font-size: 1.75rem;
  color: ${(props) => props.theme.palette.default.primary} !important;
  margin-bottom: ${(props) => props.theme.margin.half};
`
export const StyledText = styled(Text)`
  color: ${(props) => props.theme.palette.default.white};
  margin: 0;
  transition: all 500ms ease-in-out;
  transition-delay: 500ms;
  margin-bottom: ${(props) => props.theme.margin.half};
`

export const Tags = styled(Text)`
  margin: 0;
  color: ${(props) => props.theme.palette.default.greyLight};

  span {
    font-weight: 300;
    margin: 0 10px;
  }
`

export const StyledSwiper = styled(Swiper)`
  display: grid;
`

export const StyledSwiperSlide = styled(SwiperSlide)`
  display: flex;
  position: relative;
  z-index: 10;
  cursor: pointer;
  overflow: visible;
  /* transition: all 1000ms;
  transform-origin: 0% 50%; */

  @media screen and (min-width: ${(props) => props.theme.device.smartphone}) {
    &.swiper-slide-active {
      transform: scale(1.05);
    }
  }
`
