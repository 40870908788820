import { StaticImageData } from 'next/image'
import React, { PropsWithChildren } from 'react'
import { A11y, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { ButtonModifier, ButtonType } from '@Components/atoms/Button/Button'
import Col from '@Components/atoms/Col'
import Container from '@Components/atoms/Container'
import { FontSizeEyebrow } from '@Components/atoms/Eyebrow/Eyebrow'
import Headline from '@Components/atoms/Headline'
import IconLinkInternal from '@Components/atoms/Icons/IconLinkInternal'
import Row from '@Components/atoms/Row'
import { FontSizeText, FontWeightText, Text } from '@Components/atoms/Text/Text'
import { PropsWithClassName } from '@Components/helper'

import andreas from '@Assets/employees/andreas_swip.jpg'
import ina from '@Assets/employees/ina_swip.jpg'
import olli from '@Assets/employees/olli_swip.jpg'

import {
  ContentRight,
  Root,
  SlideContent,
  StyledButton,
  StyledEyebrow,
  StyledQuote,
  StyledText,
  SwiperCol,
  SwiperImage,
} from './TextAndSwiper.styles'

export interface TextAndSwiperSlideProps {
  image: StaticImageData
  alt: string
  content: React.ReactNode
}

export enum TextAndSwiperBackground {
  light = 'light',
  dark = 'dark',
}

interface Props extends PropsWithClassName {
  eyebrow: string
  headline: string
  background: TextAndSwiperBackground
  text: string
  buttonText: string
  link: string
  slides: TextAndSwiperSlideProps[]
}

export const TextAndSwiperSlides: TextAndSwiperSlideProps[] = [
  {
    image: ina,
    alt: 'Ina',
    content: (
      <>
        <StyledQuote>
          “Ein supernettes Team geprägt von Leidenschaft, Hilfsbereitschaft und
          Humor.”
        </StyledQuote>
        <Text size={FontSizeText.medium} weight={FontWeightText.normal}>
          Ina / Product Ownerin
        </Text>
      </>
    ),
  },
  {
    image: olli,
    alt: 'Oliver',
    content: (
      <>
        <StyledQuote>
          “Etwas bauen, was viele Menschen anschließend nutzen und ihnen hilft.”
        </StyledQuote>
        <Text size={FontSizeText.medium} weight={FontWeightText.normal}>
          Oliver “Zolli” K. / Symfony/Sulu Developer
        </Text>
      </>
    ),
  },
  {
    image: andreas,
    alt: 'Andreas',
    content: (
      <>
        <StyledQuote>
          “Code weglöschen, Symfony, Workshops & Talks auf Konferenzen sind
          meine Leidenschaft.”
        </StyledQuote>
        <Text size={FontSizeText.medium} weight={FontWeightText.normal}>
          Andreas / CTO
        </Text>
      </>
    ),
  },
]

const TextAndSwiper: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { eyebrow, headline, text, buttonText, background, link, slides } =
    props
  return (
    <Root className={props.className} data-testid={'text-and-swiper-root'}>
      <Container>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6}>
            <StyledEyebrow
              styleAs={3}
              background={background}
              size={FontSizeEyebrow.normal}
            >
              {eyebrow}
            </StyledEyebrow>
            <Headline styleAs={2} importance={3}>
              {headline}
            </Headline>
            <StyledText
              background={background}
              size={FontSizeText.intro}
              weight={FontWeightText.normal}
            >
              {text}
            </StyledText>
            <StyledButton
              href={link}
              modifier={ButtonModifier.primary}
              type={ButtonType.button}
              icon={<IconLinkInternal />}
            >
              {buttonText}
            </StyledButton>
          </Col>
          <SwiperCol xs={12} sm={12} md={6} lg={6}>
            <Swiper
              speed={1000}
              loop={true}
              pagination={{
                dynamicBullets: false,
                clickable: true,
              }}
              modules={[Navigation, A11y, Pagination]}
              spaceBetween={0}
            >
              {slides.map((slide, index) => (
                <SwiperSlide key={slide.image.src + '-' + index}>
                  <ContentRight background={background}>
                    <SwiperImage
                      quality={85}
                      width={952}
                      height={518}
                      src={slide.image}
                      alt={slide.alt ? slide.alt : 'Swiper Bild'}
                    />
                    <Container>
                      <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                          <SlideContent>{slide.content}</SlideContent>
                        </Col>
                      </Row>
                    </Container>
                  </ContentRight>
                </SwiperSlide>
              ))}
            </Swiper>
          </SwiperCol>
        </Row>
      </Container>
    </Root>
  )
}

export { TextAndSwiper }
