import styled from 'styled-components'

export const Root = styled.p`
  word-wrap: break-word;
  font-family: ${(props) => props.theme.font.primaryFamily};
  color: ${(props) => props.theme.palette.default.blueDeep};
  margin-bottom: ${(props) => props.theme.margin.half};
  font-size: ${(props) => props.theme.font.text.quote.normal.mobile.fontSize};
  font-weight: ${(props) =>
    props.theme.font.text.quote.normal.mobile.fontWeight};
  line-height: ${(props) =>
    props.theme.font.text.quote.normal.mobile.lineHeight};
  letter-spacing: ${(props) =>
    props.theme.font.text.quote.normal.mobile.letterSpacing};
  @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
    font-family: ${(props) => props.theme.font.text.primaryFamily};
    font-size: ${(props) =>
      props.theme.font.text.quote.normal.desktop.fontSize};
    font-weight: ${(props) =>
      props.theme.font.text.quote.normal.desktop.fontWeight};
    line-height: ${(props) =>
      props.theme.font.text.quote.normal.desktop.lineHeight};
    letter-spacing: ${(props) =>
      props.theme.font.text.quote.normal.desktop.letterSpacing};
  }
`
