import Image from 'next/image'
import styled from 'styled-components'

import Button from '@Components/atoms/Button'
import Col from '@Components/atoms/Col'
import Eyebrow from '@Components/atoms/Eyebrow'
import Quote from '@Components/atoms/Quote'
import Text from '@Components/atoms/Text'

import { defaultTheme } from '@Assets/styles/theme/theme'

import { TextAndSwiperBackground } from './TextAndSwiper'

interface StyledColLeftProps {
  background: TextAndSwiperBackground
}

export const Root = styled.section`
  background: ${(props) => props.theme.palette.default.greyUltralight};
  .swiper-pagination {
    transform: none !important;
    text-align: center;
    bottom: 0;
    left: 0;
    margin-left: -24px;
    @media screen and (min-width: ${(props) => props.theme.device.tablet}) {
      left: ${(props) => props.theme.padding.default};
      text-align: left;
    }
  }
  .swiper-pagination-bullet {
    background: ${(props) => props.theme.palette.default.white};
    &-active {
      background: ${(props) => props.theme.palette.default.primary};
    }
  }
`

const getTextColor = (background: TextAndSwiperBackground): string => {
  switch (background) {
    case TextAndSwiperBackground.light:
      return defaultTheme.palette.default.greyMiddle
    case TextAndSwiperBackground.dark:
      return defaultTheme.palette.default.white
    default:
      return defaultTheme.palette.default.white
  }
}

export const StyledEyebrow = styled(Eyebrow)<StyledColLeftProps>`
  color: ${(props) => getTextColor(props.background)};
  margin-top: ${(props) => props.theme.margin.default};
`

export const StyledText = styled(Text)<StyledColLeftProps>`
  color: ${(props) => getTextColor(props.background)};
`

export const StyledButton = styled(Button)`
  margin: 0;
`

export const SwiperImage = styled(Image)`
  position: relative;
  width: 100%;
  height: auto;
  object-fit: cover;
`

export const ContentRight = styled.div<StyledColLeftProps>`
  position: relative;
`

export const SwiperCol = styled(Col)`
  padding: 0 0 ${(props) => props.theme.padding.default} 0;
  background: ${(props) => props.theme.palette.default.blueDeep};
  p {
    color: ${(props) => props.theme.palette.default.white};
  }
`

export const StyledQuote = styled(Quote)`
  margin-top: 3rem;
`

export const SlideContent = styled.div`
  padding-bottom: ${(props) => props.theme.margin.half};
`
