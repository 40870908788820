import React, { useEffect, useRef, useState } from 'react'

import BackgroundMask from '@Components/atoms/BackgroundMask'
import { FontSizeText, FontWeightText } from '@Components/atoms/Text/Text'
import { PropsWithClassName } from '@Components/helper'
import { Routes } from '@Components/Routes'

import {
  LogoWrap,
  Root,
  StyledCol,
  StyledHeadline,
  StyledLink,
  StyledLinkText,
  StyledLogo,
  StyledRow,
  StyledScrollDown,
  StyledText,
} from './Teaser.styles'

interface Props extends PropsWithClassName {}

const Teaser = (props: Props): React.ReactElement => {
  interface Coords {
    x: number
    y: number
  }
  const [coords, setCoords] = useState<Coords>()
  const [counter, setCounter] = useState<number>(0)
  const container = useRef(null)

  useEffect(() => {
    setCoords({
      x: Math.round((50 * window.innerWidth) / 100),
      y: Math.round((50 * window.innerHeight) / 100),
    })
  }, [])

  const getXPercent = (): number => {
    if (typeof window !== 'undefined' && coords) {
      return Math.round((coords.x / window.innerWidth) * 100)
    } else {
      return 50
    }
  }

  const getYPercent = (): number => {
    if (typeof window !== 'undefined' && coords) {
      return Math.round((coords.y / window.innerHeight) * 100)
    } else {
      return 50
    }
  }

  const handleMouseMove = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setCounter(counter + 1)
    if (counter % 2 === 0) {
      return
    }
    setCoords({
      x: event.clientX,
      y: event.clientY,
    })
  }

  return (
    <Root
      ref={container}
      className={props.className}
      data-testid={'Teaser-root'}
      onMouseMove={(event) => handleMouseMove(event)}
      style={{ backgroundPosition: getXPercent() + '% ' + getYPercent() + '%' }}
    >
      <StyledRow>
        <StyledCol xs={12} sm={12} md={12} lg={12}>
          <LogoWrap>
            <StyledLink
              passHref
              href={Routes.qossmic}
              aria-label="QOSSMIC Unterseite"
            >
              <StyledLogo />
            </StyledLink>
            <StyledText
              size={FontSizeText.intro}
              weight={FontWeightText.normal}
            >
              <StyledLinkText
                passHref
                href={Routes.qossmic}
                aria-label="QOSSMIC Unterseite"
              >
                Wir sind QOSSMIC
              </StyledLinkText>
            </StyledText>
          </LogoWrap>
          <StyledHeadline styleAs={1} importance={1}>
            Erfolgreiche{' '}
            <StyledLink passHref href={Routes.services}>
              Softwarelösungen
            </StyledLink>
            ,
            <br />
            <StyledLink passHref href={Routes.services}>
              nutzerorientiert
            </StyledLink>
            ,{' '}
            <StyledLink passHref href={Routes.services}>
              innovativ
            </StyledLink>
            <br /> und{' '}
            <StyledLink passHref href={Routes.services}>
              empathisch
            </StyledLink>
            .
          </StyledHeadline>
          <StyledScrollDown />
        </StyledCol>
      </StyledRow>
      <BackgroundMask
        top={getYPercent() * 2 - 300}
        left={getXPercent() * 3 + 300}
      />
    </Root>
  )
}

export { Teaser }
