import Image from 'next/image'
import styled, { css, keyframes } from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'

import Button from '@Components/atoms/Button'
import Col from '@Components/atoms/Col'
import { EyebrowH2 } from '@Components/atoms/Eyebrow/Eyebrow.styles'
import Headline from '@Components/atoms/Headline'
import Logo from '@Components/atoms/Logos/Logo'
import Text from '@Components/atoms/Text'

interface TargetProps {
  target?: string
}

export const Root = styled.section<TargetProps>`
  height: 500px;
  position: relative;
  overflow: hidden;
  @media screen and (min-width: ${(props) => props.theme.device.tablet}) {
    height: 800px;
  }
  ${(props) =>
    props.target === 'solution-page' &&
    css`
      height: 300px !important;
      margin-bottom: 50px;

      @media screen and (min-width: ${(props) => props.theme.device.tablet}) {
        height: 642px !important;
      }

      @media screen and (max-width: ${(props) => props.theme.device.mobile}) {
        height: 300px;
      }
    `}
`
interface StyledLogoProps {
  animation: boolean
  target?: string
}

// TODO: Animation zu fade in aendern

const moveLogo = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 0.8;
}
`

const moveLogoBack = keyframes`
0% {
  opacity: 0.8;
}
100% {
  opacity: 0;
}
`

const opacityContent = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
`

const opacityContentBack = keyframes`
0% {
  opacity: 1;
}
100% {
  opacity: 0;
}
`

export const StyledLogo = styled(Logo)<StyledLogoProps>`
  overflow: hidden;
  ${(props) =>
    props.target === 'solution-page' &&
    css`
      width: 300% !important;
      height: 365% !important;
      top: 45% !important;
      left: -100% !important;
      transform: rotateY(180deg) rotate(3deg) !important;

      @media screen and (min-width: ${(props) => props.theme.device.desktop}) {
        left: -105% !important;
      }

      @media screen and (max-width: ${(props) => props.theme.device.tablet}) {
        display: none !important;
      }
    `}
  position: absolute;
  z-index: 40;
  transform: rotateY(180deg) rotate(0);
  opacity: 0.8;
  display: none;

  @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
    display: inline;
    top: 35%;
    left: -90%;
    width: 240%;
    height: 400%;
    transform: rotateY(180deg) rotate(-8.5deg);
    transition: 1500ms ease-in-out;
    ${(props) =>
      props.target === 'no-solution' &&
      props.animation &&
      css`
        animation: ${moveLogo} 1s ease-in-out;
      `}
    ${(props) =>
      props.target === 'no-solution' &&
      props.animation === false &&
      css`
        animation: ${moveLogoBack} 1s ease-in-out 2s;
      `}
  }
  @media screen and (min-width: ${(props) => props.theme.device.tablet}) {
    top: 38%;
    left: -75%;
    width: 200%;
    height: 350%;
  }
  @media screen and (min-width: 70rem) {
    top: 40%;
    left: -60%;
    width: 180%;
    height: 350%;
  }
  @media screen and (min-width: ${(props) => props.theme.device.desktop}) {
    top: 45.5%;
    left: -60%;
    width: 180%;
    height: 350%;
  }
  @media screen and (min-width: 90rem) {
    top: 48.5%;
    left: -60%;
    width: 180%;
    height: 350%;
  }
`

export const StyledSwiper = styled(Swiper)`
  position: relative;
  z-index: 20;
  .swiper-slide-active {
    ${StyledLogo} {
      top: 20%;
    }
  }
  .swiper-pagination {
    z-index: 99;
  }
`

export const CustomPagination = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
  .swiper-pagination-bullet {
    background: ${(props) => props.theme.palette.default.black};
    opacity: 0.7;
    transition: all 200ms ease-in-out;
  }
  .swiper-pagination-bullet-active {
    background: ${(props) => props.theme.palette.default.primary};
    opacity: 1.5;
  }
`
export const CustomPaginationItem = styled.div``

export const StyledSwiperSlide = styled(SwiperSlide)<TargetProps>`
  height: 800px;
  position: relative;
  z-index: 10;
  background-size: cover;
  overflow: hidden;

  ${(props) =>
    props.target === 'solution-page' &&
    css`
      height: 300px !important;

      @media screen and (min-width: ${(props) => props.theme.device.tablet}) {
        height: 642px !important;
      }

      @media screen and (max-width: ${(props) => props.theme.device.mobile}) {
        height: 300px;
      }
    `}
`
export const StyledImage = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  @media screen and (min-width: 1660px) {
    height: auto;
  }
`
export const StyledHeadline = styled(Headline)`
  line-height: 2.5rem;
  hyphens: none;
  @media screen and (max-width: ${(props) =>
      props.theme.device.tablet}) and (min-width: ${(props) =>
      props.theme.device.mobile}) {
    font-size: 1.875rem;
    margin-bottom: 1rem;
  }
`
export const StyledEyebrow = styled(EyebrowH2)`
  position: absolute;
  top: ${(props) => props.theme.padding.small};
  left: 14px;
  text-transform: uppercase;
  z-index: 99;
  color: ${(props) => props.theme.palette.default.greyDark};
  transition: all 400ms ease-in-out;
  @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
    color: ${(props) => props.theme.palette.default.white};
  }
  @media screen and (min-width: ${(props) => props.theme.device.tablet}) {
    left: 50px;
  }
`

export const SlideContent = styled.div<StyledLogoProps>`
  ${(props) =>
    props.target === 'solution-page' &&
    css`
      animation: none !important;
      @media screen and (max-width: ${(props) => props.theme.device.tablet}) {
        display: none;
      }
    `}
  ${(props) =>
    props.target === 'no-solution' &&
    props.animation &&
    css`
      animation: ${opacityContent} 1s ease-in-out;
    `}
  ${(props) =>
    props.target === 'no-solution' &&
    props.animation === false &&
    css`
      animation: ${opacityContentBack} 1s ease-in-out 2s;
    `}
  position: absolute;
  bottom: 100px;
  left: 0;
  max-width: ${(props) => props.theme.row.maxWidth};
  width: calc(100% - 1rem);
  margin: 0 auto;
  height: auto;
  z-index: 50;
  user-select: none;
`
export const SlideLogo = styled.div`
  display: flex;
  width: 90%;
  height: auto;
  svg {
    display: flex;
    width: auto;
    height: 60px;
    margin-bottom: 16px;
    @media screen and (min-width: ${(props) => props.theme.device.tablet}) {
      height: 90px;
      margin-bottom: 16px;
    }
  }
`

export const SlideHeadline = styled(Headline)<TargetProps>`
  color: ${(props) => props.theme.palette.default.black};
  font-size: 26px;
  margin-bottom: 20px;
  color: ${(props) => props.theme.palette.default.greyDark};
  @media screen and (min-width: ${(props) => props.theme.device.tablet}) {
    margin-bottom: 30px;
  }
`

export const SlideButton = styled(Button)`
  margin-top: 1.5rem;
  a {
    margin-bottom: 0;
  }
`

export const ArrowLeft = styled.div`
  position: absolute;
  top: 50%;
  left: 20px;
  width: 54px;
  height: 54px;
  border-radius: 27px;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 99;
  cursor: pointer;
  margin-top: -27px;
  background: ${(props) => props.theme.palette.default.primary};
  visibility: hidden;
  @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
    visibility: visible;
  }
  svg {
    margin-top: -1px;
    margin-right: 3px;
    path {
      stroke: ${(props) => props.theme.palette.default.white};
      stroke-width: 3px;
    }
  }
`

export const ArrowRight = styled.div`
  position: absolute;
  top: 50%;
  right: 20px;
  width: 54px;
  height: 54px;
  border-radius: 27px;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 99;
  cursor: pointer;
  margin-top: -27px;
  background: ${(props) => props.theme.palette.default.primary};
  visibility: hidden;
  @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
    visibility: visible;
  }
  svg {
    margin-top: -1px;
    margin-left: 3px;
    transform: rotate(180deg);
    path {
      stroke: ${(props) => props.theme.palette.default.white};
      stroke-width: 3px;
    }
  }
`

export const StyledText = styled(Text)`
  font-size: 1.375rem;
  line-height: 1.5rem;
  margin-bottom: 0rem;
  color: ${(props) => props.theme.palette.default.blueDeep};
  @media screen and (max-width: ${(props) => props.theme.device.tablet}) {
    line-height: 1.5rem;
    font-size: 1.275rem;
  }
`

export const StyledCol = styled(Col)``

export const Layer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 40;
  @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
    display: none;
  }
`
