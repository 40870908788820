import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@Components/helper'

import { Root } from './Quote.styles'

interface Props extends PropsWithClassName {
  children: string | React.ReactNode
}

const Quote: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { children } = props
  return (
    <Root className={props.className} data-testid={'quote-root'}>
      {children}
    </Root>
  )
}

export { Quote }
