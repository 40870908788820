import React from 'react'

import { ButtonModifier, ButtonType } from '@Components/atoms/Button/Button'
import Col from '@Components/atoms/Col'
import Container from '@Components/atoms/Container'
import { FontSizeEyebrow } from '@Components/atoms/Eyebrow/Eyebrow'
import IconLinkInternal from '@Components/atoms/Icons/IconLinkInternal'
import Row from '@Components/atoms/Row'
import { FontSizeText, FontWeightText } from '@Components/atoms/Text/Text'
import { PropsWithClassName } from '@Components/helper'
import { Routes } from '@Components/Routes'

import {
  Root,
  StyledButton,
  StyledEyebrow,
  StyledHeadlineDark,
  StyledHeadlineLight,
  StyledText,
} from './SectionTeaser.styles'

export enum SectionTeaserBackground {
  white = 'white',
  bluedeep = 'bluedeep',
  transparent = 'transparent',
}

interface Props extends PropsWithClassName {
  headlineLight?: string
  headlineDark?: string
  text: string
  buttonText: string
  buttonLink: Routes
  section: string
  background: SectionTeaserBackground
}

const SectionTeaser = (props: Props): React.ReactElement => {
  const {
    headlineLight,
    headlineDark,
    background,
    text,
    buttonText,
    section,
    buttonLink,
  } = props
  return (
    <Root
      background={background}
      className={props.className}
      data-testid={'SectionTeaser-root'}
    >
      <Container>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <StyledEyebrow
              background={background}
              size={FontSizeEyebrow.normal}
            >
              {section}
            </StyledEyebrow>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6} md={4} lg={5}>
            {headlineLight && (
              <StyledHeadlineLight styleAs={3} importance={3}>
                {headlineLight}
              </StyledHeadlineLight>
            )}
            {headlineDark && (
              <StyledHeadlineDark styleAs={3} importance={3}>
                {headlineDark}
              </StyledHeadlineDark>
            )}
          </Col>
          <Col xs={12} sm={6} md={8} lg={7}>
            <StyledText
              background={background}
              size={FontSizeText.intro}
              weight={FontWeightText.normal}
            >
              {text}
            </StyledText>
            <StyledButton
              icon={<IconLinkInternal />}
              modifier={ButtonModifier.primary}
              type={ButtonType.button}
              href={buttonLink}
            >
              {buttonText}
            </StyledButton>
          </Col>
        </Row>
      </Container>
    </Root>
  )
}

export { SectionTeaser }
