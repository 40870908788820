import styled from 'styled-components'

import Button from '@Components/atoms/Button'
import { EyebrowH2 } from '@Components/atoms/Eyebrow/Eyebrow.styles'
import Headline from '@Components/atoms/Headline'
import Text from '@Components/atoms/Text'

import { defaultTheme } from '@Assets/styles/theme/theme'

import { SectionTeaserBackground } from './SectionTeaser'

interface RootProps {
  background: SectionTeaserBackground
}

export const getBackgroundColor = (
  props: SectionTeaserBackground | undefined
) => {
  switch (props) {
    case SectionTeaserBackground.white:
      return defaultTheme.palette.default.white
    case SectionTeaserBackground.bluedeep:
      return defaultTheme.palette.default.blueDeep
    case SectionTeaserBackground.transparent:
      return 'transparent'
    default:
      return 'transparent'
  }
}

export const getTextColor = (props: SectionTeaserBackground | undefined) => {
  switch (props) {
    case SectionTeaserBackground.white:
      return defaultTheme.palette.default.black
    case SectionTeaserBackground.bluedeep:
      return defaultTheme.palette.default.white
    case SectionTeaserBackground.transparent:
      return defaultTheme.palette.default.greyMiddle
    default:
      return defaultTheme.palette.default.white
  }
}

export const Root = styled.section<RootProps>`
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
  padding-top: ${(props) => props.theme.padding.default};
  padding-bottom: ${(props) => props.theme.padding.half};
  z-index: 10;
  background: ${(props) => getBackgroundColor(props.background)};
  @media screen and (min-width: ${(props) => props.theme.device.tablet}) {
    padding-bottom: ${(props) => props.theme.padding.default};
  }
`

export const StyledEyebrow = styled(EyebrowH2)<RootProps>`
  text-transform: uppercase;
  z-index: 20;
  color: ${(props) => getTextColor(props.background)};
`

export const StyledHeadlineLight = styled(Headline)`
  color: ${(props) => props.theme.palette.default.white};
  hyphens: none;
`

export const StyledHeadlineDark = styled(Headline)`
  color: ${(props) => props.theme.palette.default.black};
  hyphens: none;
`

export const StyledButton = styled(Button)`
  align-self: flex-start;
  a {
    margin-bottom: 0;
  }
`

export const StyledText = styled(Text)<RootProps>`
  color: ${(props) => getTextColor(props.background)};
`
