import styled from 'styled-components'

export const Root = styled.svg`
  cursor: pointer;
  circle {
    transition: fill 200ms;
    &:hover {
      fill: ${(props) => props.theme.palette.default.white};
    }
  }
`
